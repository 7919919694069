<template>
  <div class="app-card">
    <div style="height: 500px">
      <quill-editor
        style="height: 440px"
        v-model="content"
        :options="{ placeholder: '请填写内容', ...editerOptions }"
      />
    </div>
    <div style="padding-top:30px">
      <a-button
      type="primary"
      @click="saveAndUpdate"
      v-if="
        $root.$data.privateState.action.includes(
          '/Document/UserServiceAgreement'
        ) || $root.$data.privateState.role === 'SuperAdmin'
      "
      >保存并更新</a-button
    >
    </div>
  </div>
</template>

<script>
import { Document } from '@/api'
export default {
  name: "SystemUserServiceAgreement",
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getDetail()
    })
  },
  data() {
    return {
      content: "",
      editerOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["link"],
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
        theme: "snow",
      },
    };
  },
  methods: {
    async getDetail() {
      const apiRes = await this.$axios
        .get(`${Document}/GetDetail?key=UserServiceAgreement`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.content = apiRes.data
    },
    async saveAndUpdate() {
      if (!this.content) {
        this.$message.error("请填写内容");
        return;
      }
      const apiRes = await this.$axios
        .post(`${Document}/UserServiceAgreement`, {
          key: 'UserServiceAgreement',
          content: this.content,
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.$message.success('用户服务协议已更新');
    },
  },
};
</script>

<style>
</style>